body {
  width: 100%;
  height: 100%;
  text-align: center;
  background: linear-gradient(to right, #3ea855, #2a2a2a, #3ea855) 0 0 / 200%;
  font-family: Product Sans, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  animation: 5s linear infinite background-pan;
  overflow: hidden;
}

body.toggled {
  animation: 10s linear infinite background-pan;
}

body.toggled > #title {
  opacity: 0;
}

body.toggled > #tiles:hover, body.toggled > .tile:hover {
  opacity: .1 !important;
}

.tiles {
  height: calc(100% - 1px);
  width: calc(100% - 1px);
  z-index: 2;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: repeat(var(--rows), 1fr);
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

.tile {
  position: relative;
}

.tile:hover:before {
  background-color: #141414;
}

.tile:before {
  content: "";
  background-color: #080808;
  position: absolute;
  inset: .5px;
}

h1 {
  color: #3ea855;
}

h3 {
  color: #fff;
}

.container {
  max-width: max-content;
  z-index: 3;
  background: #202020;
  border-radius: 10px;
  margin: 0 auto;
  padding: 20px 40px;
}

.vertical-center {
  position: relative;
  top: 45%;
  transform: translateY(-100%);
}

@keyframes background-pan {
  from {
    background-position: 0%;
  }

  to {
    background-position: -200%;
  }
}

/*# sourceMappingURL=index.b5acb079.css.map */
