body
    overflow hidden
    background linear-gradient(to right, #3ea855, #2A2A2A, #3ea855)
    background-size 200%
    animation background-pan 5s linear infinite
    font-family 'Product Sans', Helvetica, Arial, sans-serif
    font-size 14px
    line-height 1.42857143
    width 100%
    height 100%
    text-align center

    &.toggled
        animation background-pan 10s linear infinite

        & > #title
            opacity 0

        & > #tiles
        & > .tile
            &:hover
                opacity 0.1 !important

@keyframes background-pan
    from
        background-position 0% center

    to
        background-position -200% center

.tiles
    height calc(100% - 1px)
    width calc(100% - 1px)
    position absolute
    z-index 2
    display grid
    grid-template-columns repeat(var(--columns), 1fr)
    grid-template-rows repeat(var(--rows), 1fr)
    top 0
    left 0


.tile
    position relative

    &:hover
        &:before
            background-color #141414

    &:before
        background-color #080808
        content ''
        inset 0.5px
        position absolute

h1
    color #3ea855

h3
    color #ffffff

.container
    max-width max-content
    margin 0 auto
    background: #202020
    padding 20 40 20 40
    border-radius 10px
    z-index 3

.vertical-center
    top 45%
    transform translateY(-100%)
    position relative